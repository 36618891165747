var autocompleteService, placesService, results;
let currentSearchInput = document.getElementById('homehead-location-input-search');
let isNotHomePage = window.location.pathname !== "/" && !window.location.pathname.startsWith('/homepage');
let autoFillSearchText = true;
$(document).ready(function () {
	let posLat = Cookies.get('posLat');
	let posLon = Cookies.get('posLon');
	if (posLat && posLon) {
		$('#homepage-location-input-search').attr("placeholder", "Enter your location");
		$('#head-location-input-search').attr("placeholder", "Enter your location");
		$('#homehead-location-input-search').attr("placeholder", "Enter your location");
	}

	$('#homehead-location-input-search').on('focus keydown change', function(e) {
		$('#homehead-location-input-search').addClass('addBorderRadInp');
        $('#intro a.home-filter-icon').addClass('addBorderRadSearchIcon');
		if (e.which == 13 && currentFocus !== 0) {
			// console.log($("#hide-locationinput").attr('data-placeid'));
			results.value = $("#hide-locationinput").attr('data-name');
			getPlaceDetails($("#hide-locationinput").attr('data-placeid'));
			Cookies.remove('posLat');
			Cookies.remove('posLon');
			Cookies.remove('locationinput');
		}
		if(e.which == 13 && currentFocus == 0){
				document.getElementById("current-location-option").onclick();	
		}

		if((e.keyCode == 46 || e.keyCode == 8 || e.key === "Delete" || e.key == "Backspace") &&  autoFillSearchText ) {
			autoFillSearchText = false;
			$("#text-hide-locationinput").html(currentSearchInput.value);
			$("#hide-locationinput").html(currentSearchInput.value);
		}
		if(!currentSearchInput.value){
			autoFillSearchText = true
		}
	  });
	$('#head-location-input-search').on('focus keydown change', function(e) {
		$('#head-location-input-search').addClass('addBorderRadInp');
        $('#intro a.home-filter-icon').addClass('addBorderRadSearchIcon');
		if (e.which == 13 ) {
			// console.log($("#hide-locationinput").attr('data-placeid'));
			results.value = $("#hide-locationinput").attr('data-name');
			getPlaceDetails($("#hide-locationinput").attr('data-placeid'));
			Cookies.remove('posLat');
			Cookies.remove('posLon');
			Cookies.remove('locationinput');
		}
		if(e.which == 13 ){
				document.getElementById("current-location-option").onclick();	
		}

		if((e.keyCode == 46 || e.keyCode == 8 || e.key === "Delete" || e.key == "Backspace") &&  autoFillSearchText ) {
			autoFillSearchText = false;
			$("#text-hide-locationinput").html(currentSearchInput.value);
			$("#hide-locationinput").html(currentSearchInput.value);
		}
		if(!currentSearchInput.value){
			autoFillSearchText = true
		}
	  });
})
if (window.location.pathname.startsWith('/homepage')) {
	currentSearchInput = document.getElementById('homepage-location-input-search');
}
if (isNotHomePage) {
	currentSearchInput = document.getElementById('head-location-input-search');
}
function errorHandler(err) {
	if(err.code == 1) {
	   console.log("Error: Access is denied!");
	} else if( err.code == 2) {
	   console.log("Error: Position is unavailable!");
	}
 }
function getRealPlace() {
	return new Promise((resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showLocation, errorHandler);
			function showLocation(position) {
				var _lat = position.coords.latitude;
				var _lng = position.coords.longitude;
				if(_lat && _lng) {
					$.ajax({
						url: '/api/getgeocodebylatlong',
						type: 'POST',
						contentType: 'application/json; charset=utf-8',
						data: JSON.stringify({ lat: _lat, long: _lng  }),
						success: function (res) {
							if(res.code == 0 && res.data) {
								resolve(res.data.formatted_address)
							}else{
								console.log("errr=====")
							}
						},
					});
				}
			 }
			// navigator.geolocation.getCurrentPosition(function(position){
			// 	var _lat = position.coords.latitude;
			// 	var _lng = position.coords.longitude;
			// 	if(_lat && _lng) {
			// 		$.ajax({
			// 			url: '/api/getgeocodebylatlong',
			// 			type: 'POST',
			// 			contentType: 'application/json; charset=utf-8',
			// 			data: JSON.stringify({ lat: _lat, long: _lng  }),
			// 			success: function (res) {
			// 				if(res.code == 0 && res.data) {
			// 					resolve(res.data.formatted_address)
			// 				}
			// 			},
			// 		});
			// 	}
			// })
		}
	})
}


async function getLocation(callback) {
	if (navigator.geolocation) {
		const res = await getRealPlace()
		currentSearchInput.value = 'Find your location...';
		currentSearchInput.disabled = true;
		if(results && results.style) results.style.display = 'none';
		navigator.geolocation.getCurrentPosition(showPosition);
		navigator.geolocation.watchPosition(function(position) {
			currentSearchInput.value = res;
			currentSearchInput.disabled = false;
		},
		function(error) {
			if (error.code == error.PERMISSION_DENIED) {
				alert("Oops, we can't find your location");
			}
			currentSearchInput.disabled = false;
			currentSearchInput.value = '';
		});
	} else {
		alert("Geolocation is not supported by this browser.");
	}
}


function searchAutoComplete(locname = 'Enter Your Location', latitude, longitude) {
	let isHomePage = window.location.pathname === '/';
	let isSecondHomePage = window.location.pathname.includes('/homepage');
	if (isHomePage || isSecondHomePage) {
		$('#homepage-location-input-search').val(locname);
		$('#head-location-input-search').val(locname);
		Cookies.set('locationinput', locname, { path: '/' });
	}else{
		$('#head-location-input-search').val(locname);
	}
	console.log("kkk333333=================")
	setlocationlocalforage(locname, latitude, longitude, null);
	if(isHomePage || isSecondHomePage){
		commonhomepage();
	}
	document.getElementById('inputSwitchSpan').innerHTML = locname;
}

async function showPosition(position) {
	if (position && position.coords && position.coords.latitude && position.coords.longitude) {
		const res = await getRealPlace()
		document.getElementById('inputSwitchSpan').innerHTML = res;
		const locname = res;
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		$("#lng").val(longitude);
		$("#lat").val(latitude);
		_updatePositions(position);
		searchAutoComplete(locname, latitude, longitude);
	}
}

google.maps.event.addDomListener(window, 'load', initialize);

async function initialize() {
	setTimeout(() => {
	$('#head-location-input-search').prop("disabled", false);
	if(isNotHomePage){
		console.log("not home pgae=====")
		results = document.getElementById('autocomplete-results-basic')
	}
	else {
		console.log(" home pgae=====")
		results = document.getElementById('autocomplete-results');
	}
	// Bind listener for address search
	currentSearchInput && google.maps.event.addDomListener(currentSearchInput, 'input', function() {
		if(currentSearchInput && !currentSearchInput.value) { 
			$("#hide-locationinput").html("");
		}
		if(results && results.style) results.style.display = 'block';
		$(".subheadersearch").addClass("subheadersearch-border-radius");
		$("#homehead-location-input-search").addClass("subheadersearch-border-radius");
		currentSearchInput && getPlacePredictions(currentSearchInput.value);
	});
	// Show results when address field is focused (if not empty)
	currentSearchInput && google.maps.event.addDomListener(currentSearchInput, 'focus', function() {
		if (currentSearchInput.value !== '') {
			if(results && results.style) results.style.display = 'block';
			$(".subheadersearch").addClass("subheadersearch-border-radius");
			$("#homehead-location-input-search").addClass("subheadersearch-border-radius");
			currentSearchInput && getPlacePredictions(currentSearchInput.value);
		}
	});

	// Hide results when click occurs out of the results and inputs
	google.maps.event.addDomListener(document, 'click', function(e) {
		if ((e.target.parentElement.className !== 'pac-container') && (e.target.parentElement.className !== 'pac-item') && (e.target.tagName !== 'INPUT')) {
			$('#mobilelocationdiv').removeClass("input-border-focus");
			if(results && results.style) results.style.display = 'none';
			$(".subheadersearch").removeClass("subheadersearch-border-radius");
			$("#homehead-location-input-search").removeClass("subheadersearch-border-radius");
		}
	});

	autocompleteService = new google.maps.places.AutocompleteService();
	const map = document.getElementById('map-place');
	placesService = new google.maps.places.PlacesService(map);
},2000);
}

function getPlacePredictions(search) {
	setTimeout(() => {

	const center = { lat: 40.749933, lng: -73.98633 }
	const defaultBounds = {
		north: center.lat + 0.1,
		south: center.lat - 0.1,
		east: center.lng + 0.1,
		west: center.lng - 0.1,
	};
	autocompleteService.getPlacePredictions({
		input: search,
		bounds: defaultBounds,
		strictBounds: false,
	}, callback);

},1000);
//const autocomplete = new google.maps.places.Autocomplete(input, options);

}
// Get place details
function getPlaceDetails(placeId) {
	var request = {
		placeId: placeId
	};
	placesService.getDetails(request, function(place, status) {
	if (status === google.maps.places.PlacesServiceStatus.OK) {
		if (place) {
			var latitude = place.geometry.location.lat();
			var longitude = place.geometry.location.lng();
			let locname = place.name;
			if(place.formatted_address){
				locname = place.formatted_address
			}
			searchAutoComplete(locname, latitude, longitude);
		}
		if(results && results.style) results.style.display = 'none';
	}
	});
}
var items;
// Place search callback
function callback(predictions, status) {
	// Empty results container
	setTimeout(() => {
	if(results){
		results.innerHTML = '';
		// Place service status error
		if (status != google.maps.places.PlacesServiceStatus.OK) {
			$("#hide-locationinput").html("")
			$("#autocomplete-results-basic").css("display", "none");
			// results.innerHTML = '<div class="pac-item pac-item-error">Your search returned no result. Status: ' + status + '</div>';
			return;
		}
		if(!predictions.length > 0){
			$("#autocomplete-results-basic").css("display", "none");
		}
		// Build output with custom addresses
		if(isNotHomePage){
			results.innerHTML += `<div class="pac-item custom cursor-pointer dropdown-item current-place py-2 font-13" style="color: #02843D;" onclick="getLocation()" id="current-location-option"><img src="/images/current-location.png" style="margin-right: 6px; margin-top: -3px"/>Detect Current location</div>`;
		}
		else{
		    results.innerHTML += `<div class="pac-item custom cursor-pointer py-2 font-13" style="color: #0073bb;" onclick="getLocation()" id="current-location-option"><span class="pac-icon pac-icon-marker ml-2"></span>Enter Your Location</div>`;
		}


		if(isMobile) $('#search-result-hidden').removeClass('d-none');
		$('.pac-container').addClass('pac-container-search shadow-none border-top-0');

		// Build output for each prediction
		for (var i = 0, prediction; prediction = predictions[i]; i++) {
			if(i === 0) {
				if($("#homehead-location-input-search").length) {
					const searchTextLocation =  $("#homehead-location-input-search").val();
					if(autoFillSearchText) {
						const searchSubStr = prediction.description.substr(0, searchTextLocation.length);
						// $("#homehead-location-input-search").val(prediction.description);
						$("#text-hide-locationinput").html(searchSubStr);
						$("#hide-locationinput").html(prediction.description);
					} else {
						$("#text-hide-locationinput").html(searchTextLocation);
						$("#hide-locationinput").html(searchTextLocation);
					}
					$("#hide-locationinput").attr('data-placeid', prediction.place_id);
					$("#hide-locationinput").attr('data-name', prediction.terms[0].value);
				}
			}
			// Insert output in results container
			if(isNotHomePage){
				results.innerHTML += '<div class="pac-item cursor-pointer dropdown-item border-0 ml-2 font-13" style="color:#555;" data-placeid="' + prediction.place_id + '" data-name="' + prediction.terms[0].value + '"><img src="/images/suggest-location.png" style="margin-right: 6px; margin-top: -3px"/>' + prediction.description + '</div>';
			}
			else 
			    results.innerHTML += '<div class="pac-item cursor-pointer border-0 ml-2 font-13" style="color:#555;" data-placeid="' + prediction.place_id + '" data-name="' + prediction.terms[0].value + '"><span class="pac-icon pac-icon-marker"></span>' + prediction.description + '</div>';
		
			$('.pac-item').addClass('pb-2');
		}
		if (isNotHomePage) {
			results.innerHTML += 
				'<div class="pac-item" style="text-align: right; margin-right: 16px; border: none"><img src="/images/google-place.png"/></div> <div class="pac-item" style="color: #979797; font-size: 12px; margin-left: 16px; border: none">Recent location</div>';
			localforage.getItem('recentlocation', function(err, locn ){
				if(locn){
					let newlocation = JSON.parse(locn);
					makeRecentLocationDropDown(newlocation);
				} else {
					console.log("no recent locations");
				}
			});
			function makeRecentLocationDropDown(newarray){
				for(i=0; i<newarray.length; i++){
					results.innerHTML += '<div class="pac-item recent-location cursor-pointer dropdown-item"><img src="/images/clock-recent.png" style="margin-right: 6px; margin-top: -3px"/>'+newarray[i]+'</div>';
				}
			}
		}
	}
	items = $(".pac-item");

	// Results items click
	for (var i = 1, item; item = items[i]; i++) {
		item.onclick = function() {
			if (this.dataset.placeid) {
				results.value = this.dataset.name;
				getPlaceDetails(this.dataset.placeid);
				Cookies.remove('posLat');
				Cookies.remove('posLon');
				Cookies.remove('locationinput');
			}
		};
	}
	return items;
},1000);
}

$("#homehead-location-input-search").click((e) => {
	if($("#hide-locationinput").text()){
		$("#homehead-location-input-search").val($("#hide-locationinput").text())
		$("#hide-locationinput").css("background", 'white')
	}
})

$('body').on('click',".dropdown-item",function(e) {
	e.stopPropagation();
	document.getElementById('inputSwitchSpan').innerHTML = $(this).text();
	$("#slectboxcurrentlocation").hide();
	if (isMobile) {
	  $(".header-searchcustom").show();
	  $(".header-locationcustom").hide();
	}
	getPlaceDetails(this.dataset.placeid);
	Cookies.remove('posLat');
	Cookies.remove('posLon');
	Cookies.remove('locationinput');
	if($(this).hasClass('dropdown-item-menusection')){}
	else{
		commonajaxcallforhome("search");
	}
})

$('#homehead-location-input-search, #head-location-input-search').on('focus keydown change', function(e) {
	items = isNotHomePage ? $(".dropdown-item") : $(".pac-item");
	if(e.which == 40) {	
		if( currentFocus == -1) {
			++currentFocus;
			$(items[currentFocus]).addClass("activeLocation")
			$("#hide-locationinput").text("Enter Your Location")
			return currentFocus
		}

		if( 0 <= currentFocus < 6){
			++currentFocus;
			$(items[currentFocus - 1]).removeClass("activeLocation")
			$(items[currentFocus]).addClass("activeLocation")
			$("#hide-locationinput").attr("data-placeid",items[currentFocus].dataset.placeid )
			$("#hide-locationinput").attr("data-name",items[currentFocus].dataset.name )
			$("#hide-locationinput").text(items[currentFocus].textContent )
			return currentFocus
		}
		else {
			currentFocus = 5;
			$(items[5]).addClass("activeLocation")
			$("#hide-locationinput").data("placeid",items[currentFocus].dataset.placeid )
			$("#hide-locationinput").data("name",items[currentFocus].dataset.name )
			$("#hide-locationinput").text(items[currentFocus].textContent )
			return currentFocus
		}
	}
	if(e.which == 38) {
		if(-1 < currentFocus){
			--currentFocus;
			$(items[currentFocus]).addClass("activeLocation")		
			$(items[currentFocus + 1]).removeClass("activeLocation")		
			$("#hide-locationinput").data("placeid",items[currentFocus].dataset.placeid )
			$("#hide-locationinput").data("name",items[currentFocus].dataset.name )
			$("#hide-locationinput").text(items[currentFocus].textContent )
			return currentFocus;
		}
		else  {
			currentFocus = -1;
			$(items[0]).removeClass("activeLocation")
			$("#hide-locationinput").data("placeid",items[currentFocus].dataset.placeid )
			$("#hide-locationinput").data("name",items[currentFocus].dataset.name )
			$("#hide-locationinput").text(items[currentFocus].textContent )
			return currentFocus;
		}
	}
	if(e.which == 13) {
		if (currentFocus !== 0 && items[currentFocus].dataset.placeid) {
			results.value = $("#hide-locationinput").attr('data-name');
			getPlaceDetails($("#hide-locationinput").attr('data-placeid'));
			Cookies.remove('posLat');
			Cookies.remove('posLon');
			Cookies.remove('locationinput');
		}
		
	}
	

});

let  currentFocus = -1;
google.maps.event.addDomListener(window, 'load', initialize);
